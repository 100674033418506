<template>
  <div class="delivery-details" style="padding-bottom: 80px">
    <PageHeader title="商品出库" />
    <div class="header_view  m-lr-20 m-tb-10 p-tb-5 p-lr-20 flex j-between a-center">
      <div class="item_view flex  p-lr-20 j-between">
        <div class="flex column  f-1" style="width: calc(100% - 100px);">
          <h4 style="line-height: 0px" class="f14">单据信息</h4>
          <div class="t flex ">
            <span class="name ">交货单号：</span>
            <div class="el-1 f-1 ">
              <ShowTooltips :txt-info="Info.SapDeliveryID ? Info.SapDeliveryID : Info.WriteDateDeliveryID" />
            </div>
          </div>
          <div class="t flex">
            <span class="name">创建时间：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="$minCommon.formatDate(new Date(Info.WriteDate), 'yyyy-MM-dd hh:mm') " />
            </div>
          </div>
          <div class="t flex">
            <span class="name">销售订单号：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info=" Info.OrderID" />
            </div>
          </div>
          <div class="t flex">
            <span class="name">备注：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="Info.Reamrks" />
            </div>
          </div>
          <div class="t flex">
            <span class="name"> 期望到货日期：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info=" Info.ExpectedDeliveryDate " />
            </div>
          </div>

        </div>
        <div class="status flex column m-top-20 a-center">
          <div class="f14">状态：</div>
          <div class="m-top-10 weight" style="color: #3A78F1">{{ (Info.SapDeliveryID || Info.DeliveryID) ? Info.dStatusStr : Info.StrStatus }}</div>
        </div>
      </div>
      <div class="item_view flex  m-left-30  p-lr-20 j-between" :style="{width:ReciptInfos ? '' :'70%',border:ReciptInfos ? '' :'none' }">
        <div class="flex column f-1" style="width: calc(100% - 100px);">
          <h4 style="line-height: 0px" class="f14">收货信息</h4>
          <div class="t flex">
            <span class="name">收货单位：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="ReciveInfos.SendCompanyName" />
            </div>
          </div>
          <div class="t flex">
            <span class="name">收货地址：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="ReciveInfos.FullAddress" />
            </div>
          </div>
          <div class="t flex">
            <span class="name">收货人：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="ReciveInfos.ContactName" />
            </div>
            <span class="name">部门科室：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="ReciveInfos.DeptName" />
            </div>
          </div>
          <div class="t flex">
            <span class="name">联系电话：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="ReciveInfos.ContactTel" />
            </div>
          </div>
          <div class="t flex">
            <span class="name">运输条件：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="ReciveInfos.StorageConditionName" />
            </div>
          </div>

        </div>
      </div>
      <div v-if="ReciptInfos" class="item_view flex m-left-30 p-lr-20 j-between" style="border: none">
        <div class="flex column f-1" style="width: calc(100% - 100px);">
          <h4 style="line-height: 0px" class="f14">收票信息</h4>
          <div class="t flex">
            <span class="name">收票地址：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="ReciptInfos.FullAddress" />
            </div>
          </div>
          <div class="t flex">
            <span class="name">收票单位：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="ReciptInfos.BuyCompanyName ? ReciptInfos.BuyCompanyName : ReciptInfos.SendCompanyName" />
            </div>
          </div>
          <div class="t flex">
            <span class="name">收票人：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="ReciptInfos.ContactName" />
            </div>
          </div>
          <div class="t flex">
            <span class="name">联系电话：</span>
            <div class="el-1 f-1">
              <ShowTooltips :txt-info="ReciptInfos.ContactTel" />
            </div>
          </div>
          <div class="t flex">
            <span class="name" />
            <div class="el-1 f-1">
              <ShowTooltips txt-info="" />
            </div>
          </div>
          <div class="t flex">
            <span class="name" />
            <div class="el-1 f-1">
              <ShowTooltips txt-info="" />
            </div>
          </div>
          <div class="t flex">
            <span class="name" />
            <div class="el-1 f-1">
              <ShowTooltips txt-info="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header_view m-lr-20 ">
      <div class="flex  min-border-bottom m-bottom-10 p-lr-20 j-between a-center">
        <h4 style="margin: 10px 0">出库详情</h4>
        <div class="flex">
          <el-button v-if="Info.Status !== 5" size="mini" round type="primary" @click="printTable">打印</el-button>
          <!-- <el-button size="mini" round type="primary" @click="() => visibleWL = true">测试物流</el-button> -->

          <!--          <el-button size="mini" round type="primary">关闭订单</el-button>-->
          <!-- <el-button v-if="Info.Status === 4" size="mini" round type="warning" @click="backOrder">退回</el-button> -->
          <el-button
            v-if="Info.Status === 4"
            size="mini"
            round
            type="primary"
            :loading="isLoading0"
            @click="restart"
          >重新发货</el-button>
          <el-button
            v-if="((Info.SapDeliveryID || Info.DeliveryID) && Info.dStatus !== 0 && Info.Status !== 5) || Info.Status !== 5"
            size="mini"
            round
            type="danger"
            @click="closeOrder"
          >关闭</el-button>
          <el-button
            v-if="(Info.SapDeliveryID || Info.DeliveryID) && Info.dStatus === 0"
            size="mini"
            round
            type="primary"
            :loading="isLoading1"
            @click="CreateStockOut"
          >确认发货</el-button>
          <el-button
            v-if="$minCommon.checkMeau('spckjyfh', userBottons) && Info.Status === 2"
            size="mini"
            round
            type="primary"
            @click="JYEvent"
          >检验发货</el-button>
        </div>
      </div>

      <div class="m-lr-10 m-bottom-10">

        <el-table
          ref="multipleTable"
          :data="tableData"
          :header-cell-style="{ background: 'rgb(247,248,252)' }"
          tooltip-effect="dark"
          show-header

          row-key="id"
          border
        >
          <el-table-column align="center" prop="InvCode" label="物料编号" />
          <el-table-column align="center" prop="InvName" label="物料名称" />
          <el-table-column align="center" prop="BrandName" label="品牌" />
          <el-table-column align="center" prop="MaterialSubTypeName" label="物料类型">
            <template scope="scope">
              {{ scope.row.MaterialSubTypeName }}
              {{ scope.row.MaterialTypeName }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Specification" label="规格" />
          <el-table-column align="center" prop="StockUnitN" label="单位" />
          <el-table-column align="center" prop="LotNum" label="批号/序列号" />
          <el-table-column align="center" prop="ExpiredDate" label="有效期截至">
            <template scope="scope">
              {{ $minCommon.formatDate(new Date(scope.row.ExpiredDate) ,'yyyy-MM-dd') }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="OutNum" label="订单数量" />
          <el-table-column align="center" prop="OutNum" label="出库数量" />
          <el-table-column align="center" prop="DINum" label="UDI码" />
          <el-table-column align="center" prop="StorageConditionName" label="运输条件" />
          <el-table-column align="center" prop="StockName" label="仓库" />
        </el-table>
      </div>

    </div>

    <!-- 检验发货  确认 start -->
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="isvisible" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">交货单通过/退回</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => isvisible = false" />
      </div>
      <div class="flex j-center a-center m-tb-30 column">
        <!-- <div class="flex f-start m-bottom-20" style="width:50%">
          <el-radio-group v-model="isSure">
            <el-radio :label="1">同意</el-radio>
            <el-radio :label="0">拒绝</el-radio>
          </el-radio-group>
        </div> -->

        <el-input
          v-model="textarea"
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
        />
        <div class="flex j-center m-top-20" style="width:50%">

          <el-button type="danger" :loading="isLoading2" @click="backCancel">退回</el-button>
          <el-button type="primary" :loading="isLoading3" @click="okEvent">通过</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 检验发货  确认 end -->
    <!-- 出库发货  确认 start -->
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="visible" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">出库发货</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => visible = false" />
      </div>
      <div class="status_ flex j-center column a-center m-tb-30">
        <i class="el-icon-circle-check" style="font-size: 80px;color: #67C23A" />
        <p class="f24" style="line-height: 10px">你的出库发货申请已提交，请等待审核</p>
        <p class="f18" style="line-height: 10px">交货单号：<span style="color:  #67C23A">{{ Info.SapDeliveryID ? Info.SapDeliveryID : Info.WriteDateDeliveryID }}</span></p>
        <p>提交时间： {{ $minCommon.formatDate(new Date(), 'yyyy-MM-dd hh:mm') }}</p>
        <el-button type="primary" style="margin-top: 20px" @click="sureCk">确认</el-button>
      </div>
    </el-dialog>
    <!-- 出库发货  确认 end -->

    <!-- 出库发货  已审核  start -->
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="visibleCK" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">出库发货</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => visibleCK = false" />
      </div>
      <div class="status_ flex j-center column a-center m-tb-30">
        <i class="el-icon-circle-check" style="font-size: 80px;color: #67C23A" />
        <p class="f24" style="line-height: 10px">已审核</p>
        <p class="f18" style="line-height: 10px">
          出库单号： <span style="color:  #67C23A">{{ Info.StockOutId }}</span>
        </p>

        <div class="m-top-30">
          <el-button @click="() => visibleCK = false">关闭</el-button>
          <el-button type="primary" @click="printTable">打印出库单</el-button>
          <el-button type="primary" @click="writeWLInfo">填写物流信息</el-button>
        </div>

      </div>
    </el-dialog>
    <!-- 出库发货  已审核 end -->

    <!-- 填写物流信息    start -->
    <el-dialog class="DepartmentBox" :close-on-click-modal="false" :visible.sync="visibleWL" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">填写物流信息</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => visibleWL = false" />
      </div>
      <div class="status_ flex j-start column a-center m-tb-30">
        <div class="form_view flex a-center j-start m-tb-10">
          <el-radio-group v-model="form.resource">
            <el-radio :label="3">自配送</el-radio>
            <el-radio :label="6">物流配送</el-radio>
          </el-radio-group>
        </div>
        <div v-if="form.resource === 6" id="select_c" class="form_view flex  j-start m-tb-10">
          <div class="name">物流公司：</div>
          <el-select v-model="nameWL" style="width: 100%" size="mini" placeholder="请选择物流公司">
            <el-option
              v-for="item in WLinfo"
              :key="item.ExpressNo"
              :label="item.ExpressName"
              :value="item.ExpressName"
            />
          </el-select>
          <div id="i_cjq">
            <el-input v-model="nameWL" placeholder="点击右侧箭头选择物流公司" style="height:100%" size="mini" />
          </div>
        </div>

        <div v-if="form.resource === 6" class="form_view flex a-center j-start m-tb-10">
          <div class="name">物流单号：</div>
          <el-input
            v-model="form.LogisticsCode"
            style="margin-left: 6px"
            size="mini"
            placeholder="请输入内容"
            clearable
          />
          <i class="el-icon-circle-plus-outline f25 m-left-10 cursor" style="color: #409EFF" @click="addCode" />
        </div>
        <div v-if="form.resource === 6" class="flex j-center column a-center" style="width: 100%">
          <div v-for="(item,index) in selects" :key="index" class="form_view flex a-center j-start m-tb-10">
            <div class="name">物流单号：</div>
            <el-input
              v-model="item.LogisticsCode"
              size="mini"
              style="margin-left: 6px"
              placeholder="请输入内容"
              clearable
            />
            <i class="el-icon-remove-outline f25 m-left-10 cursor" style="color: #409EFF" @click="delCode(index)" />
          </div>
        </div>
      </div>
      <div class="m-top-30 flex j-center m-bottom-20">
        <el-button @click="() => visibleWL = false">取消</el-button>
        <el-button type="primary" @click="WLadd">确认</el-button>
      </div>
    </el-dialog>
    <!-- 填写物流信息   end -->
  </div>
</template>

<script>
import Index from '@/minxin/management/delivery-details'
export default Index
</script>
<style lang="scss" scoped>
.header_view {
   box-shadow: 0 2px 12px rgba(0,0,0,0.1) ;
   border: 1px solid #EBEEF5;
   height: auto;
  .item_view {
    width: 33.3%;
    border-right: 1px dashed #ccc;
    .status {
      width: 100px;
    }
    .t {
      flex: 1;
      padding: 2px 0;
      font-size: 12px;
      .name {
        max-width: 90px !important;
        display: inline-block;
      }

    }
  }
}
.status_ {
  width: 100%;
  height: auto;
}
.form_view {
  width: 60%;
  //background-color: red;
  .name {
    width: 100px;
  }
}
#select_c {
  position: relative;
}
#i_cjq  ::v-deep {
    .el-input__inner {
      border: 0;
      height: 20px;
      margin-top: 5px;
      margin-left: 5px;
    }
  }
#i_cjq {
  position: absolute;
  left: 88px;
  top: 0;
  width: 50%;
  z-index: 700;
  border: none;
  height: 25px;
  overflow: hidden;
  display: flex;
  align-content: center;
}
h4 {
  margin: 10px 0;
}
</style>
