import PageHeader from '@/components/PageHeader'
import ShowTooltips from '@/components/showtext'
import root from '@/minxin/root'
const Index = {
  name: 'DeliveryDetails',
  components: {
    PageHeader,
    ShowTooltips
  },
  mixins: [root],
  data() {
    return {
      root_name: 'spck',
      root_msg: '检验发货',
      tableData: [],
      visible: false,
      visibleCK: false,
      visibleWL: false,
      selects: [],
      isvisible: false,
      Info: {}, // 单据信息
      ReciveInfos: {}, // 收货信息
      ReciptInfos: {}, // 收票信息
      isSure: false,
      textarea: '', // 理由
      isShowJY: '',
      WLinfo: [], // 物流信息
      nameWL: '', // 手动填写的物流名称
      form: {
        resource: 6,
        LogisticsName: '',
        LogisticsCode: '',
        IDX: 0
      },
      isLoading0: false, // 重新发货
      isLoading1: false, // 确认发货
      isLoading2: false, // 退回
      isLoading3: false // 通过
    }
  },
  watch: {
    'form.resource'(a) {
      console.log(a)
      if (a !== 6) return
    }
  },
  mounted() {
    console.log(this.$route.params)
    console.log('asdadsd', this.userBottons)
    this.ExpressCompanyInfoLst()
    return this.getDetail(this.$route.params.DeliveryId)
  },
  methods: {
    getDetail(DeliveryId) {
      if (DeliveryId) {
        this.$api.MateriaklExportWarehouseDetails({
          DeliveryID: this.$route.params.DeliveryId
        }).then(res => {
          this.tableData = res.Message.Details
          this.Info = res.Message.Info
          this.ReciveInfos = res.Message.ReciveInfos
          this.ReciptInfos = res.Message.ReciptInfos
        })
        return
      }
      this.$api.MateriaklExportWarehouseDetailsByStockOutID({
        StockOutID: this.$route.params.StockOutID
      }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.tableData = res.Message.Details
        this.Info = res.Message.Info
        this.ReciveInfos = res.Message.ReciveInfos
        this.ReciptInfos = res.Message.ReciptInfos
      })
      return
    },
    // 获取物流信息
    ExpressCompanyInfoLst() {
      this.$api.ExpressCompanyInfoLst().then(res => {
        console.log(res)
        this.WLinfo = res.Message
      })
    },
    // 重新发货
    restart() {
      if (!this.Info.StockOutId) return this.$message.info('没有出库单号')
      this.isLoading0 = true
      this.$api.StockOutReOut({
        StockOutID: this.Info.StockOutId
      }).then(res => {
        this.isLoading0 = false
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$message.success('操作成功')
        this.sureCk()
        this.getDetail(this.$route.params.DeliveryId)
      })
    },
    // 处理物流数据
    setWLdata() {
      const data = []
      const arr = this.selects.filter(item => item.LogisticsCode)
      arr.map(item => {
        data.push({
          IDX: item.IDX, // 物流信息idx
          LogisticsName: this.form.LogisticsName,
          LogisticsCode: item.LogisticsCode
        })
      })
      data.unshift({
        IDX: this.form.IDX, // 物流信息idx
        LogisticsName: this.form.LogisticsName,
        LogisticsCode: this.form.LogisticsCode
      })
      return data
    },
    group(array, subGroupLength) {
      let index = 0
      const newArray = []
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength))
      }
      return newArray
    },
    // 打印
    printTable() {
      var title = ''
      var divElements = ''
      // console.log(divElements)
      var printWindow = window.open(Math.random() * 1000, '_blank', '')
      // // 构造一个表格
      let str_tr = ''
      let str_td = ''
      let num = 0
      const arr12 = [{
        label: '物料编号',
        prop: 'InvCode'
      }, {
        label: '物料名称',
        prop: 'InvName'
      }, {
        label: '品牌',
        prop: 'BrandName'
      }, {
        label: '物料类型',
        prop: 'MaterialSubTypeName'
      }, {
        label: '规格',
        prop: 'Specification'
      }, {
        label: '单位',
        prop: 'StockUnitN'
      }, {
        label: '批号/序列号',
        prop: 'LotNum'
      }, {
        label: '有效期截至',
        prop: 'ExpiredDate'
      }, {
        label: '订单数量',
        prop: 'OutNum'
      }, {
        label: '出库数量',
        prop: 'OutNum'
      }, {
        label: 'UDI码',
        prop: 'DINum'
      }, {
        label: '运输条件',
        prop: 'StorageConditionName'
      }, {
        label: '仓库',
        prop: 'StockName'
      }]
      arr12.map(item => {
        num += 1
        str_tr += `
            <th style="font-size: 12px;font-weight:400;min-width:100px" >${item.label}</th>
        `
      })
      arr12.map((item1, index) => {
        // console.log(index)
        console.log(item1)
        this.tableData.map((item, index1) => {
          console.log('cjq', item)
          str_td += `<td  id="testTd" style="text-align:center;border:solid #e5e5e5 1px;font-size: 12px">${item[item1.prop] ? item[item1.prop] : ''}</td>]`
        })
      })
      const testTD = str_td.split(']')
      testTD.splice(str_td.split(']').length - 1, 1)
      // console.log('str_td', testTD)
      // console.log('num', num)
      let arr = []
      let str = ''
      arr = this.group(testTD, num)
      // console.log(arr)
      arr.map((item, index) => {
        str += `<tr>${item.join('')}</tr>`
      })
      const table_ = `
          <table border="1" class='table-0' id="dasasasdsad" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border:none;width:100%">
          <tr>
            ${str_tr}
          </tr>
          ${str}
        </table>
      `
      // console.log(table_)
      divElements = `<div class='div-0'> ${table_}</div>`
      printWindow.document.open()
      printWindow.document.write('<html><head><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Cache-Control" content="no-cache" /><meta http-equiv="Expires" content="0" /><title>' + title + '</title>')
      printWindow.document.write(`<style type="text/css">
       html, body {
         font-size: 12px;
         margin: 0;
       }
      .p-lr-20 {padding: 0 20px; }
      .m-bottom-20 {margin-bottom: 20px; }
      .weight { font-weight: 700 }
      .f14 { font-size:12px}
      .f20 {font-size:12px}
      .flex {display:flex}
      .j-center {justify-content: center;}
      .column { flex-direction: column;}
      .a-center {align-items: center;}
      .f-warp { flex-wrap: wrap; }
      .j-start {justify-content: flex-start;}
      .m-tb-10 {margin: 10px 0;}
      .m-tb-20 {margin: 20px 0;}
      .j-between {justify-content: space-between;}
      .m-top-10 {margin-top: 10px }
      .element.style {width: auto; }
      .table_info {
        max-width: 1000px;
        margin: 0 auto;
      }
      table{table-layout: fixed;}
      td{word-break: break-all; word-wrap:break-word;}
      .div-0 {page-break-after: always}
      </style>`
      )
      printWindow.document.write('</head><body>')
      printWindow.document.write(divElements)
      printWindow.document.write('</body></html>')
      printWindow.document.write(`<script type="text/javascript">
      window.onload = function() {
        class Test {
          constructor() {
            this.windowHeight = null // 窗口高度
            this.pageIndex = 0 // 当前页
            this.height_print = '' // 初始高度
            this.max_height = null // 最大高度
            this.tbody_tr = '' // 获取表格子集
            this.splice_arr = [] // 被删掉的元素
            this.header_tr = null // 表头的tr 不改动
            this.table_main = null // 表格 父级
            this.table_main_height = 0 // 表格高度
            this.bottom_veiw_height = 0 // 表底高度
            this.top_veiw_height = 0 // 表头高度
            this.table_footer_height = 0 //  // 表格合计高度
            this.top_veiw_html = null // 表头的内容、
            this.bottom_veiw_html = null // 表底的内容
            this.title_view_html = null //  顶部标题dom
            this.table_footer_html_0 = null // 表格合计内容
            this.table_footer_html_1 = null // 表格合计内容
          }
          init() {
            this.windowHeight = window.screen.height
             this.max_height =  Math.round(this.windowHeight  / 2)
             console.log('this.max_height ',this.max_height )
            const t_b_view = document.getElementsByClassName('adsadasd1')
            this.table_main_height = document.getElementById('dasasasdsad').offsetHeight // 表格高度
            // this.table_main = document.getElementById('dasasasdsad') // 表格 父级
            this.table_main = document.getElementsByClassName('table-0')[ this.pageIndex] // 表格 父级
            // document.getElementsByClassName('div-0')[this.pageIndex].style.height =  this.max_height + 'px'
            // console.log( this.table_main.childNodes)
            this.tbody_tr = this.table_main.childNodes[1].children // 获取表格子集
            this.header_tr = this.tbody_tr[0].innerHTML // 表头的tr 不改动
            this.title_view_html = document.getElementsByClassName('header')[0].innerHTML // 顶部标题dom
            this.table_footer_height = (document.getElementsByClassName('table_footer')[0].offsetHeight) * 2 // 表格合计高度
            this.table_footer_html_0 = document.getElementsByClassName('table_footer')[0].innerHTML // 表格合计内容
            this.table_footer_html_1 = document.getElementsByClassName('table_footer')[1].innerHTML // 表格合计内容
            for (let i = 0; i < t_b_view.length; i++) {
              this.top_veiw_height = t_b_view[0].offsetHeight
              this.bottom_veiw_height = t_b_view[1].offsetHeight
              this.top_veiw_html = t_b_view[0].innerHTML
              this.bottom_veiw_html = t_b_view[1].innerHTML
            }
            this.heightA()
            this.isSet()

          }
      
          // 是否换页
          isSet() {
            if (this.height_print <= this.max_height) return // 不需要换页
            if (this.height_print > this.max_height) {
               this.setData()
            }
          }
          // 处理换页的数据
          setData() {
            const arr = []
            for (let i = this.tbody_tr.length - 1; i >= 1; i--) {
              if (this.height_print > this.max_height) {
                // 将一个节点存起来
                arr.push(this.tbody_tr[i])
                // 删除一个节点
                this.table_main.childNodes[1].removeChild(this.tbody_tr[i])
                this.heightA()
              }
            }
            this.tbody_tr = arr
            this.initDocument()
          }
      
          // 计算高度 当前页高度
          heightA() {
               
              this.height_print = document.getElementsByClassName('div-0')[this.pageIndex].offsetHeight
              // document.getElementsByClassName('div-0')[0].style.background = 'red'
              // console.log("最大高度",this.max_height)
              // console.log('目前高度', this.height_print)
            // return this.height_print
          }
          // 为页面添加新的页
          initDocument() {
            this.pageIndex += 1
            let new_tr_td = ''
            for (let i = 0; i < this.tbody_tr.length; i++) {
              new_tr_td += '<tr>' + this.tbody_tr[i].innerHTML + '</tr>'
            }
      
            // 新的表格
            const table_ = "<table border='1' class='table-0' id='dasasasdsad' cellpadding='0' cellspacing='0' style='border-collapse:collapse;border:none;width:100%'>" + this.header_tr + new_tr_td + '</table>'
            const dom_new = "<div class='div-0'><div class='header weight f20 flex j-center column a-center m-tb-20'>" + this.title_view_html + '</div>' + "<div class='flex f-warp adsadasd1 m-tb-10'>" + this.top_veiw_html + '</div>' + table_ + "<div class='flex p-lr-20 header table_footer' style='height: 25px; border-top: none; border-right: 1px solid rgb(235, 238, 245); border-bottom: 1px solid rgb(235, 238, 245); border-left: 1px solid rgb(235, 238, 245); border-image: initial; line-height: 25px;'>" + this.table_footer_html_0 + '</div>' + "<div class='flex p-lr-20 header table_footer' style='height: 25px; border-top: none; border-right: 1px solid rgb(235, 238, 245); border-bottom: 1px solid rgb(235, 238, 245); border-left: 1px solid rgb(235, 238, 245); border-image: initial; line-height: 25px;'>" + this.table_footer_html_1 + '</div>' + "<div class='flex f-warp adsadasd1 m-tb-10'>" + this.bottom_veiw_html + '</div> </div> '
            //   window.document.write(dom_new)
            const conentDiv = document.createElement('div')
            conentDiv.innerHTML = dom_new
            document.body.appendChild(conentDiv)
            this.init()
          }
        }
        setTimeout(() => {
              const test = new Test()
        test.init()
        }, 60)
      
      }
      </script>`)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(function() {
        printWindow.print()
        printWindow.close()
      }, 100)
    },
    // 物流信息确认
    WLadd() {
      this.form.LogisticsName = this.nameWL
      if (this.form.resource !== 6) {
        this.$message.info('你选择了自配送')
        setTimeout(() => {
          this.$router.push({
            name: 'delivery-goods',
            params: {
              isCache: 6
            }
          })
        }, 1000)
        return
      }
      if (!this.form.LogisticsName) return this.$message.error('请填写物流公司')
      if (!this.form.LogisticsCode) return this.$message.error('请填写物流单号')
      this.$api.SaveStockOutExpress({
        StockOutId: this.Info.StockOutId,
        DeliveryID: this.Info.DeliveryId,
        stockOutLogisticsInfos: this.setWLdata()
      }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$message.success('保存成功')
        this.visibleWL = false
        this.visibleCK = false
        setTimeout(() => {
          this.$router.push({
            name: 'delivery-goods',
            params: {
              isCache: 6
            }
          })
        }, 1000)
      })
    },
    // sureCK() {
    //   // this.isvisible = true // 展示是否审核通过的弹窗
    //   this.visible = true
    /**
     *
     *
     *
     */
    // },
    // 添加单号
    addCode() {
      this.selects.push({
        LogisticsName: '',
        IDX: 0
      })
    },
    // 确认发货
    CreateStockOut() {
      this.isLoading1 = true
      this.$api.CreateStockOut({
        DeliveryID: this.$route.params.DeliveryId
      }).then(res => {
        this.isLoading1 = false
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.visible = true
        if (this.$minCommon.checkMeau('spckjyfh', this.userBottons)) {
          this.getDetail(this.$route.params.DeliveryId)
          // this.sureCK()
          return
        }
        // this.$router.push({
        //   name: 'delivery-goods',
        //   params: {
        //     isCache: 6
        //   }
        // })
      })
    },
    // 确认 关掉成功弹窗
    sureCk() {
      if (this.$minCommon.checkMeau('spckjyfh', this.userBottons)) return this.visible = false
      this.$router.push({
        name: 'delivery-goods',
        params: {
          isCache: 6
        }
      })
    },
    // backOrder 退回
    backOrder() {
      if (!this.textarea) return this.$message.info('请填写退回理由')
      this.isLoading2 = true
      this.$api.ValidStockOut({
        DeliveryID: this.$route.params.DeliveryId,
        StockOutId: this.Info.StockOutId,
        ValidReason: this.textarea,
        Status: 4 //  3 审核通过  4 退回
      }).then(res => {
        this.isvisible = false
        this.isLoading2 = false
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$message.success('退回成功')
        this.getDetail(this.$route.params.DeliveryId)
        setTimeout(() => {
          this.$router.push({
            name: 'delivery-goods',
            params: {
              isCache: 6
            }
          })
        }, 1000)
      })
    },
    // 填写物流信息
    writeWLInfo() {
      this.visibleWL = true
      this.nameWL = ''
      this.form.resource = 6
      this.form.LogisticsName = ''
      this.form.LogisticsCode = ''
      this.selects = []
    },
    // 通过
    okEvent() {
      this.isLoading3 = true
      // 检验通过
      this.$api.ValidStockOut({
        DeliveryID: this.$route.params.DeliveryId,
        StockOutId: this.Info.StockOutId,
        ValidReason: this.textarea,
        Status: 3 //  3 审核通过  4 退回
      }).then(res => {
        this.isLoading3 = false
        console.log(res)
        this.isvisible = false
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$message.success('审核成功')
        this.getDetail(this.$route.params.DeliveryId)
        this.visibleCK = true
        this.textarea = ''
      })
    },
    // 取消
    backCancel() {
      // this.closeOrder()

      this.backOrder()
    },
    // 检验发货
    JYEvent() {
      this.isvisible = true
      // this.textarea = ''
    },
    // 关闭
    closeOrder() {
      this.$confirm('是否确认退出', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.StockOutCloese({
          DeliveryID: this.$route.params.DeliveryId,
          StockOutId: this.Info.StockOutId
        }).then(res => {
          console.log(res)
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.$message.success('关闭成功')
          setTimeout(() => {
            this.$router.push({
              name: 'delivery-goods',
              params: {
                isCache: 6
              }
            })
          }, 1000)
        })
      })
    },
    // 删除单号
    delCode(index) {
      this.selects.splice(index, 1)
    }
  }
}
export default Index
