
<template>
  <el-tooltip :content="txtInfo" placement="top" effect="light" :disabled="isDisabled">
    <div class="wordnowrap" :class="{isLink: 'link-text'}" @mouseenter="isShowTooltip" @click="linkTo">{{ txtInfo }}</div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'ShowTooltips',
  props: {
    txtInfo: {
      type: String,
      default: ''
    },
    isLink: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      isDisabled: false
    }
  },
  methods: {
    isShowTooltip(e) {
      const clientWidth = e.target.clientWidth
      const scrollWidth = e.target.scrollWidth
      const arrList = Array.from(e.target.classList)
      if (scrollWidth > clientWidth) {
        this.isDisabled = false
        if (!arrList.includes('hover-blue')) {
          e.target.classList.add('hover-blue')
        }
      } else {
        this.isDisabled = true
        e.target.classList.remove('hover-blue')
      }
    },
    linkTo() {
      if (this.isLink) {
        this.$emit('linkTo')
      }
    }
  }
}
</script>

<style scoped>
.wordnowrap{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hover-blue:hover{
  color:rgb(64, 158, 255);
}
.link-text{
  color: rgb(64, 158, 255);
  cursor: pointer;
}
</style>
